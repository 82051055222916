(function(window){ var App = function (){
return {
	get id (){ return "595fdf16-97ce-4f23-a87b-6c8a62193f04"; },
	get version (){ return "1.0.0"; },
	get appSpaceId (){ return null; },
	get appState (){ return null; },
	get name (){ return "HCM_RL5"; },
	get codeName (){ return "v5"; },
	get contextPath (){ return null; },
	get description (){ return ""; },
	get indexId (){ return "1"; },
	get mobileAppId (){ return null; },
	get mobileTemplate (){ return null; },
	get platform (){ return "web"; },
	get appVersion (){ return "1.0.0"; },
	get senderKey (){ return null; },
	get iam2systemId (){ return null; },
	get iam2systemSecretKey (){ return null; },
	get iam2Connected (){ return null; },
	get iam2DefaultUser (){ return null; },
	get iam2DefaultPwd (){ return null; },
	get defaultLang (){ return "th"; }
}};
window.__pgd__ = new App(); })(window)
window.__ENV__ = "PGR";
